//libraries
import { Grid, Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

//constants
import useStyles from "./styles";

import DragAndDropList from "../../orderDetails/components/trackDriver/component/dragableComponent";

type CreateNewDriverProps = {
  orgId: string;
  orders: any;
  activeRouteId?: any;
};

const ActiveRouteComponent: React.FC<CreateNewDriverProps> = (props) => {
  const classes = useStyles();

  const [deliveryitems, setDeliveryItems] = useState();
  const [Routeditems, setRoutedItems] = useState();

  useEffect(() => {
    setRoutedItems(props?.orders?.routed_orders);

    setDeliveryItems(props?.orders?.delivery_bin_orders);
  }, [props?.orders]);
  return (
    <>
      <Grid item xs={12} md={6}>
        <DragAndDropList
          orders={Routeditems}
          activeRouteId={props.activeRouteId}
          setSelectedRoute={() => {}}
          highlightRoute={false}
          showRoute={{}}
        />

        <Typography variant="h6" style={{ marginTop: "12px" }}>
          Delivery Bin
        </Typography>
        <Grid
          className={classes.gridContainer}
          style={{ alignItems: "baseline" }}
          container
          spacing={2}
        >
          <Grid item xs={7} md={7}>
            <Box mt={2}>
              <Typography variant="body1">
                Orders that are currently with the driver but not actively being
                worked on.
              </Typography>
            </Box>
          </Grid>
          <DragAndDropList
            orders={deliveryitems}
            activeRouteId={props.activeRouteId}
            setSelectedRoute={() => {}}
            highlightRoute={false}
            showRoute={{}}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ActiveRouteComponent;
