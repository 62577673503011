import React, { useState, useCallback, useEffect } from "react";
import Map, { Marker, Popup, Source, Layer } from "react-map-gl";

import "mapbox-gl/dist/mapbox-gl.css";
import { Grid } from "@material-ui/core";

interface Destination {
  lat: number;
  long: number;
  label: string;
  address: string;
}

interface MapProps {
  apiKey: string;
  transportMode: "car" | "bike";
  start: { lat: number; long: number };
  destinations: Destination[];
  showOverlay?: boolean;
  children?: React.ReactNode;
  activeRouteId: number;
}

const DeliveryMap: React.FC<MapProps> = ({
  apiKey,
  transportMode,
  start,
  destinations,
  showOverlay = false,
  children,
  activeRouteId,
}) => {
  const [viewport, setViewport] = useState({
    latitude: start.lat,
    longitude: start.long,
    zoom: 7,
  });

  const [route, setRoute] = useState(null); // Store the route GeoJSON

  const [isRightOverlayVisible, setIsRightOverlayVisible] = useState(true); // State to control right overlay visibility

  const [selectedDestination, setSelectedDestination] =
    useState<Destination | null>(null);

  const transportIcon =
    transportMode === "car"
      ? "https://maps.google.com/mapfiles/kml/shapes/cabs.png"
      : "https://maps.google.com/mapfiles/kml/shapes/cycling.png";

  const handleMarkerClick = useCallback((destination: Destination) => {
    setSelectedDestination(destination);
  }, []);

  useEffect(() => {
    if (destinations.length > 0) {
      const lastDestination = destinations[destinations.length - 1];
      const waypoints = destinations
        .slice(0, -1)
        .map((dest) => `${dest.long},${dest.lat}`);
      const waypointsString = waypoints.join(";");

      const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${
        start.long
      },${start.lat};${waypointsString};${lastDestination.long},${
        lastDestination.lat
      }?geometries=geojson&access_token=${"pk.eyJ1Ijoid2VlbC1hZG1pbiIsImEiOiJjbTNveXBxbnIwOWI5MmtwdGRyYnVwamRiIn0.cM0Trr2ikxgjoCfhQqesxw"}`;

      // Fetch route from Mapbox Directions API
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          if (data.routes && data.routes.length > 0) {
            setRoute(data.routes[0].geometry);
          }
        })
        .catch((error) => console.error("Error fetching route:", error));
    }
  }, [start, destinations]);

  return (
    <Grid
      item
      xs={12}
      md={12}
      style={{ position: "relative", width: "100%", height: "600px" }}
    >
      <Map
        initialViewState={viewport}
        style={{ width: "100%", height: "100%" }}
        mapboxAccessToken={
          "pk.eyJ1Ijoid2VlbC1hZG1pbiIsImEiOiJjbTNveXBxbnIwOWI5MmtwdGRyYnVwamRiIn0.cM0Trr2ikxgjoCfhQqesxw"
        }
        mapStyle="mapbox://styles/mapbox/streets-v11"
        onMove={(evt) => setViewport(evt.viewState)}
      >
        {/* Start Marker */}
        <Marker latitude={start.lat} longitude={start.long}>
          <img
            src={transportIcon}
            alt="start icon"
            style={{ width: "30px", height: "30px" }}
          />
        </Marker>

        {/* Destination Markers */}
        {destinations.map((dest, index) => (
          <Marker
            key={index}
            latitude={dest.lat}
            longitude={dest.long}
            onClick={() => handleMarkerClick(dest)}
          >
            <img
              src={"https://maps.google.com/mapfiles/kml/paddle/blu-circle.png"}
              alt="destination icon"
              style={{ width: "30px", height: "30px", cursor: "pointer" }}
            />
          </Marker>
        ))}
        {/* Route Line */}
        {route && (
          <Source id="route" type="geojson" data={route}>
            <Layer
              id="route-layer"
              type="line"
              paint={{
                "line-color": "#007aff",
                "line-width": 4,
              }}
            />
          </Source>
        )}
        {/* Popup for Selected Destination */}
        {selectedDestination && (
          <Popup
            latitude={selectedDestination.lat}
            longitude={selectedDestination.long}
            closeOnClick={false}
            onClose={() => setSelectedDestination(null)}
          >
            <div>
              <h4>{selectedDestination.label}</h4>
              <p>{selectedDestination.address}</p>
            </div>
          </Popup>
        )}
      </Map>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{
          position: "absolute",
          top: "50%",
          left: "10px",
          transform: "translateY(-50%)",
          height: "80%", // Set height to 80% of the map's height
          width: "calc(100% - 20px)", // Adjust width to fit within the parent
          pointerEvents: "none", // Allow clicks to pass through to the map
          overflowY: "hidden",
          alignItems: "start",
        }}
        className="no-scrollbar"
      >
        {children &&
          React.Children.map(children, (child, index) => {
            // Conditionally render the right child
            if (index === 1 && !isRightOverlayVisible) return null;
            return <div style={{ pointerEvents: "auto" }}>{child}</div>;
          })}
      </Grid>
    </Grid>
  );
};

export default DeliveryMap;
