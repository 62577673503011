import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Grid } from "@material-ui/core";
import DeliveriesListing from "../Deliverieslisting";
import { updateRouteOrder } from "../../../../../services/api/requests";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../../hooks";

type DriverOrderProps = {
  orders: any;
  activeRouteId?: any;
  setSelectedRoute: (route: Object) => void;
  highlightRoute: boolean;
  showRoute: Object;
};
const DragAndDropList: React.FC<DriverOrderProps> = (props) => {
  const org = useAppSelector((state) => state.organization);

  const { driverId } = useParams();

  const [items, setItems] = useState(props.orders);

  useEffect(() => {
    setItems(props.orders);
  }, [props.orders]);

  const handleOnDragEnd = async (result: any) => {
    try {
      if (!result.destination) return;
      const reorderedItems = Array.from(items);
      const [movedItem] = reorderedItems.splice(result.source.index, 1);
      reorderedItems.splice(result.destination.index, 0, movedItem);
      const reorderedIds = reorderedItems.map((item: any) => item.id);
      await updateRouteOrder(
        org.org_id,
        driverId,
        props.activeRouteId,
        reorderedIds
      );

      setItems(reorderedItems);
    } catch (error) {}
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <Grid
            container
            direction="column"
            spacing={2}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {items?.map((item, index) => (
              <DeliveriesListing
                index={index}
                item={item}
                handleSelectedRoute={() => props.setSelectedRoute(item)}
                highlightRoute={props.highlightRoute}
                showRoute={props.showRoute}
              />
            ))}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragAndDropList;
